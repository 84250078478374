import ContentWrapper from "src/components/contentwrapper/ContentWrapper";
import SectionTitle from "src/components/kommendefilmer/SectionTitle";
import { ICommonProps, getStaticServerData } from "src/lib/server/sanity";
import { GetStaticProps } from "next";
import styled, { css } from "src/lib/styles/css";
import theme from "src/components/themes/DefaultTheme";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import Head from "next/head";

export const getStaticProps: GetStaticProps = getStaticServerData(async (context) => {
	return {};
});



//#region [Props]
type Custom404Props = {
} & ICommonProps;
//#endregion

//#region [Component]
export default function Custom404({ appData }: Custom404Props) {
	return <SContentWrapper>
		<Head>
			<meta name="robots" key="robots" content="noindex, nofollow" />
		</Head>
		<SSectionTitle>404-ARRR!</SSectionTitle>
		<SSectionSubTitle>Splitte mine bramseil!</SSectionSubTitle>
		<p>Vi har lett og lett over de syv hav, men finner dessverre ikke siden du er ute etter. Kanskje skyldes dette at siden du leter etter ikke finnes eller at du ble gitt feil adresse. Eller kanskje har noen landkrabber begravd den?</p>

		<SSectionParagraphTitle>Vi anbefaler deg å prøve en av følgende:</SSectionParagraphTitle>
		<ul>
			<li>Filmwebs <FilmwebLink to="/">forside</FilmwebLink></li>
			<li><FilmwebLink to="/program">Kinoprogrammet</FilmwebLink></li>
			<li><FilmwebLink to="/streamingguide">Streamingguiden</FilmwebLink></li>
			<li>Bruk søkefeltet i menyen</li>
		</ul>
		<p>
		Fortsatt usikker eller har du forslag til noe som burde vært endret? Send oss gjerne en epost til <a href="mailto:kundeservice@filmweb.no">kundeservice@filmweb.no</a>
		</p>
	</SContentWrapper>;
}
//#endregion

//#region [Styles]
const SContentWrapper = styled(ContentWrapper)`
	color: ${theme.textColor};
	max-width: 960px;
	margin-bottom: 2rem;

	a {
		color: ${theme.palette.turkis2};
	}
`;

const SSectionTitle = styled.h1`
	margin-top: 2rem;
	font-size: ${theme.fonts.tittelL.size};
	margin-bottom: 2rem;
	text-align: center;
`;

const SSectionSubTitle = styled.h2`
	font-weight: normal;
	font-size: ${theme.fonts.tittelM.size};
`;

const SSectionParagraphTitle = styled.h3`
	font-weight: normal;
	font-size: ${theme.fonts.tittelS.size};
	margin-top: 2rem;
`;
//#endregion